import { Box, Image, chakra, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import IconSvg from "ui/shared/IconSvg";

interface Props {
  className?: string;
  TokenAddress?: string;
}
interface Coin {
  name: string;
  path: string;
  address: string;
}
const TokenLogoPlaceholder = ({ className, TokenAddress }: Props) => {
  const bgColor = useColorModeValue("gray.200", "gray.600");
  const color = useColorModeValue("gray.400", "gray.200");
  const [mainCoin, setMainCoin] = useState<Coin | null>(null) 

  const checkMainCoin = () => {
    const listCoin: Coin[] = [
      {
        name: "Now Coin",
        path: "/favicon/coin-logo/NOW-logo.png",
        address: "0xC7999CF56E4C9B5EC8F5c1769c858FA62966b2D5",
      },
      {
        name: "ETH",
        path: "/favicon/coin-logo/ETH-logo.png",
        address: "0xed9a60416842C8707990f2204294784F31B3d088",
      },
      {
        name: "BNB",
        path: "/favicon/coin-logo/BNB-logo.png",
        address: "0xE8Db8b650aD604269A34Ed5A9d29d2341De5d1E6",
      },
      {
        name: "USDT",
        path: "/favicon/coin-logo/USDT-logo.png",
        address: "0x6DeA3a20f23DbaCD52046d4f96bbeb3E95045545",
      },
      {
        name: "BTC",
        path: "/favicon/coin-logo/BTC-logo.png",
        address: "0xB4cdeEB98066F08E44a2f50Fd47bf58da8e90f81",
      },
    ];
    listCoin.forEach((token, index) => {
      if(TokenAddress === token.address) {
        setMainCoin(token)
      }
    });
  };

  useEffect(() => {
    checkMainCoin()
  }, [TokenAddress])

  if (!!mainCoin) {
    return (
      <Box h="100%" w="auto">
        <Image
          className={className}
          src={mainCoin.path}
          alt={mainCoin.name}
        />
      </Box>
    );
  }
  return (
    <IconSvg
      className={className}
      fontWeight={600}
      bgColor={bgColor}
      color={color}
      borderRadius="base"
      name="token-placeholder"
      transitionProperty="background-color,color"
      transitionDuration="normal"
      transitionTimingFunction="ease"
    />
  );
};

export default chakra(TokenLogoPlaceholder);
