import { Flex, Box, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import { useAppContext } from 'lib/contexts/app';
import * as cookies from 'lib/cookies';
import useHasAccount from 'lib/hooks/useHasAccount';
import useNavItems, { isGroupItem } from 'lib/hooks/useNavItems';
import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import IconSvg from 'ui/shared/IconSvg';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import NetworkMenu from 'ui/snippets/networkMenu/NetworkMenu';

import NavLink from './NavLink';
import NavLinkGroupDesktop from './NavLinkGroupDesktop';
import NavLinkItem from './NavLinkItem';

const NavigationDesktop = () => {
  const appProps = useAppContext();
  const cookiesString = appProps.cookies;

  const isNavBarCollapsedCookie = cookies.get(cookies.NAMES.NAV_BAR_COLLAPSED, cookiesString);
  let isNavBarCollapsed;
  if (isNavBarCollapsedCookie === 'true') {
    isNavBarCollapsed = true;
  }
  if (isNavBarCollapsedCookie === 'false') {
    isNavBarCollapsed = false;
  }

  const { mainNavItems, accountNavItems } = useNavItems();

  const hasAccount = useHasAccount();

  const [ isCollapsed, setCollapsedState ] = React.useState<boolean | undefined>(isNavBarCollapsed);

  const handleTogglerClick = React.useCallback(() => {
    setCollapsedState((flag) => !flag);
    cookies.set(cookies.NAMES.NAV_BAR_COLLAPSED, isCollapsed ? 'false' : 'true');
  }, [ isCollapsed ]);

  const chevronIconStyles = {
    bgColor: useColorModeValue('white', 'black'),
    color: useColorModeValue('blackAlpha.400', 'whiteAlpha.400'),
    borderColor: 'divider',
  };

  const isExpanded = isCollapsed === false;

  return (
    
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      position="sticky"
      top={ 0 }
      flexDirection="row"
      alignItems="stretch"
      bgColor={'#251350'}
      px={{ lg: isExpanded ? 6 : 4, xl: isCollapsed ? 4 : 6 }}
      py={{ lg: 5, xl: 6 }}
      zIndex={ 9 }
      width={"100%"}
      { ...getDefaultTransitionProps({ transitionProperty: 'width, padding' }) }
      sx={{
        '&:hover #expand-icon': {
          display: 'block',
        },
      }}
    >
      <Box
        as="header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        w="100%"
        pl={{ lg: 3, xl: '15px' }}
        pr={{ lg: 3, xl: '15px' }}
        h={ 10 }
        transitionProperty="padding"
        transitionDuration="normal"
        transitionTimingFunction="ease"
      >
        <NetworkLogo/>
        {/* { Boolean(config.UI.sidebar.featuredNetworks) && <NetworkMenu isCollapsed={ isCollapsed }/> } */}
        <Box as="nav">
          <Flex
            display={{ base: 'none', lg: 'flex' }}
            position="relative"
            flexDirection="row"
            px={{ lg: 4, xl: 4 }}
          >
          { mainNavItems.map((item) => {
              if (isGroupItem(item)) {
                return <NavLinkGroupDesktop key={ item.text } item={ item }/>;
              } else {
                return <NavLinkItem key={ item.text } item={ item }/>;
              }
            }) }
          </Flex>
        </Box>
      </Box>
      { hasAccount && (
        <Box as="nav" >
          <Flex as="ul"
          display={{ base: 'none', lg: 'flex' }}
          position="relative"
          flexDirection="row"
          px={{ lg: 4, xl: 4 }}
        >
            { accountNavItems.map((item) => <NavLinkItem key={ item.text } item={ item }/>) }
          </Flex>
        </Box>
      ) }
    </Flex>
  );
};

export default NavigationDesktop;
